import { memo, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import Swiper from "react-id-swiper";
import AppGlobalsContext from "app/AppGlobalsContext";
import "./ProductsCarousel.scss";
import ProductCard from "@brand/Product/default/Product";
import { NAVLOGS_SHOW_SLIDES_NUMBER_PRODUCTS_CAROUSEL } from "app/constants";
import IconNextPlain from "app/pages/.shared/static/icons/IconNextPlain";
import IconPrevPlain from "app/pages/.shared/static/icons/IconPrevPlain";

const IMG_SIZES_DESKTOP = {
	large: { width: 330, height: 212 },
	xlarge: { width: 330, height: 212 },
};

const ProductsCarousel = ({ products = [], showSmartDPOnRootUrl, onItemClick = () => {} }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const showArrows = !isMobile && products.length > 3;

	const [swiper, setSwiper] = useState(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const updateSwiper = swiper => {
		setSwiper(swiper);
	};

	const slidePrev = () => {
		swiper.slidePrev();
	};

	const slideNext = () => {
		swiper.slideNext();
	};

	const swiperParams = {
		shouldSwiperUpdate: true,
		getSwiper: updateSwiper,
		slidesPerView: NAVLOGS_SHOW_SLIDES_NUMBER_PRODUCTS_CAROUSEL[resolution],
		spaceBetween: 17,
		centeredSlides: isMobile && products.length === 1,
	};

	useEffect(() => {
		if (swiper) {
			swiper.on("slideChangeTransitionEnd", () => {
				const isBeginning = swiper?.isBeginning;
				const isEnd = swiper?.isEnd;

				setIsBeginning(isBeginning);

				setIsEnd(isEnd);
			});
		}
	}, [swiper]);

	return (
		<div className="products-carousel">
			<Swiper {...swiperParams}>
				{products.map(product => {
					return (
						<div
							className="products-carousel__item"
							key={product.uri}
							onClick={onItemClick}
							data-testid={`product-${product?.uri}`}
						>
							<ProductCard
								product={product}
								showSmartDPOnRootUrl={showSmartDPOnRootUrl}
								desktopImageSizes={IMG_SIZES_DESKTOP}
							/>
						</div>
					);
				})}
			</Swiper>
			{showArrows && !isEnd && swiper && (
				<div
					className="products-carousel__button products-carousel__button--next"
					onClick={slideNext}
				>
					<IconNextPlain />
				</div>
			)}
			{showArrows && !isBeginning && swiper && (
				<div
					className="products-carousel__button products-carousel__button--previous"
					onClick={slidePrev}
				>
					<IconPrevPlain />
				</div>
			)}
		</div>
	);
};

ProductsCarousel.propTypes = {
	products: PropTypes.arrayOf(PropTypes.object),
	showSmartDPOnRootUrl: PropTypes.bool,
	onItemClick: PropTypes.func,
};

export default memo(ProductsCarousel);
