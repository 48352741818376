import { memo } from "react";
import PropTypes from "prop-types";
import "./CenteredLogoBlock.scss";
import RelativeLink from "app/pages/.shared/RelativeLink";
import Paragraphs from "app/pages/.shared/Paragraphs";

const CenteredLogoBlock = ({ title, text, image, color, cta = {} }) => {
	return (
		<div className="centered-logo-block">
			{image && (
				<div className="centered-logo-block__picto">
					<img src={image} />
				</div>
			)}

			{title && (
				<div className="centered-logo-block__title" style={{ color: color }}>
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</div>
			)}

			{text && (
				<div className="centered-logo-block__content">
					<Paragraphs paragraphs={text} enableHTMLFormatting />
				</div>
			)}

			{cta.link && (
				<div className="centered-logo-block__cta">
					<RelativeLink
						variant="primary"
						to={{ pathname: cta.link }}
						style={{ backgroundColor: cta.bgColor }}
						target={cta?.openInNewWindow ? "_blank" : "_self"}
					>
						{cta.label}
					</RelativeLink>
				</div>
			)}
		</div>
	);
};

CenteredLogoBlock.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	color: PropTypes.string,
	cta: PropTypes.object,
};

export default memo(CenteredLogoBlock);
