import { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import AppGlobalsContext from "app/AppGlobalsContext";
import { SDP_SEARCH_MARKETING_BLOCKS, STATIC_PAGE_BLOCKS } from "app/constants";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { FIRST_MERCHANDISING_POSITION } from "src/@brand/appConstants";
import "./DynamicBlocksRenderer.scss";
import classNames from "classnames";

import {
	CenteredLogoBlock,
	MerchCarouselBlock,
	MultiBlocks,
	PhotoArticles,
	SmartDPBanner,
	SmartDPHighlighted,
	SmartDPReasons,
	SmartDPReInsurance,
	ProductsCarouselBlock,
	OneMerchBanner,
} from "./blocks";
import sortBy from "lodash/sortBy";

const DynamicBlocksRenderer = ({
	blocks = [],
	DividerComponent,
	fetchMerchandisings,
	merchandisings = [],
	alternateColors = true,
	oneMerchBannerMerchandising = {},
	userIsConnected,
}) => {
	const { resolution, shop, productsVisibility, partnerCode } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const hasMerch = blocks.some(
		block =>
			block._type === SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL ||
			block._type === SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER
	);

	useEffect(() => {
		if (hasMerch) {
			fetchMerchandisings({ shop, productsVisibility, partnerCode });
		}
	}, [hasMerch]);

	return (
		<div
			className={classNames({
				"dynamic-blocks-renderer": true,
				"dynamic-blocks-renderer--alternate-colors": alternateColors,
			})}
		>
			{blocks.map((block, index) => {
				let blockComponent;

				switch (block._type) {
					case STATIC_PAGE_BLOCKS.CENTERED_LOGO_BLOCK:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<CenteredLogoBlock
									title={block.title}
									text={block.text}
									image={block.image}
									color={block.color?.hex}
									cta={block.cta}
								/>
							</div>
						);
						break;
					case STATIC_PAGE_BLOCKS.PHOTO_ARTICLES:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<PhotoArticles
									title={block.title}
									articles={block.articles}
									isMobile={isMobile}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.REASONS_TO_BOOK:
						blockComponent = (
							<>
								<div className="dynamic-blocks-renderer__item">
									<SmartDPReasons reasonsToBook={block.items} />
								</div>

								{block.highlightedReasonPicture && (
									<div className="dynamic-blocks-renderer__item">
										<SmartDPHighlighted
											resolution={resolution}
											picture={block.highlightedReasonPicture}
											reasonToBook={block.items[0]}
										/>
									</div>
								)}
							</>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL: {
						// on ajoute temporairement ce code pour garder le même comportement que sur
						// le carousel XX
						if (merchandisings.length >= 4) {
							const merchandisingsPosition0 = merchandisings.filter(
								merchandising =>
									merchandising.position === FIRST_MERCHANDISING_POSITION &&
									block?.category?.name?.includes(
										merchandising.displayOptions?.category?.name
									)
							);

							const sortedsMerchandisingsPosition0 = sortBy(
								merchandisingsPosition0,
								"headerIndex"
							);

							const merchandisingListToDisplay = sortedsMerchandisingsPosition0.slice(
								0,
								4
							);

							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<MerchCarouselBlock
										introduction={block.intro}
										merchandisingTitle={block.merchTitle}
										merchandisings={merchandisingListToDisplay}
										title={block.title}
									/>
								</div>
							);
						}
						break;
					}
					case SDP_SEARCH_MARKETING_BLOCKS.BANNER:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<SmartDPBanner
									resolution={resolution}
									imageDesktop={block.imageDesktop}
									imageDesktopOverlay={block.imageDesktopOverlay}
									imageMobileOverlay={block.imageMobileOverlay}
									imageDesktopThumbnail={block.imageDesktopThumbnail}
									imageMobile={block.imageMobile}
									imageMobileThumbnail={block.imageMobileThumbnail}
									url={block.link}
									isImmersive={block.immersive}
									openInNewWindow={block.openInNewWindow}
								/>
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.REINSURANCE:
						if (
							!block.hideIfLogged ||
							(Boolean(block.hideIfLogged) && !userIsConnected)
						) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<SmartDPReInsurance
										resolution={resolution}
										title={block.title}
										introduction={block.intro}
										image={block.image}
										thumbnail={block.thumbnail}
										text={block.text}
										openInNewWindow={block.cta?.openInNewWindow}
										buttonLabel={block.cta?.label}
										url={block.cta?.link}
									/>
								</div>
							);
						}
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER:
						if (!isEmpty(oneMerchBannerMerchandising)) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<OneMerchBanner
										resolution={resolution}
										title={block.title}
										merchandising={oneMerchBannerMerchandising}
									/>
								</div>
							);
						}
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MULTI_BLOCKS:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<MultiBlocks
									blocks={block.children}
									DividerComponent={DividerComponent}
									fetchMerchandisings={fetchMerchandisings}
									merchandisings={merchandisings}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.PP_CAROUSEL:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<ProductsCarouselBlock
									introduction={block.intro}
									title={block.title}
									cta={block.cta}
								/>
							</div>
						);
						break;
					default: {
						blockComponent = null;
					}
				}

				return (
					blockComponent && (
						<Fragment key={index}>
							{index > 0 && DividerComponent}
							{blockComponent}
						</Fragment>
					)
				);
			})}
		</div>
	);
};

DynamicBlocksRenderer.propTypes = {
	blocks: PropTypes.array,
	DividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	merchandisings: PropTypes.array,
	alternateColors: PropTypes.bool,
	oneMerchBannerMerchandising: PropTypes.object,
	userIsConnected: PropTypes.bool,
};

export default DynamicBlocksRenderer;
