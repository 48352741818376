import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import "./SmartDPReInsurance.scss";
import Separator from "app/pages/.shared/Separator";
import RelativeLink from "app/pages/.shared/RelativeLink";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const IMG_SIZES = {
	small: { width: 600 },
	medium: { width: 600 },
	large: { width: 350, height: 196 },
	xlarge: { width: 350, height: 196 },
};

const SmartDPReInsurance = ({
	resolution,
	title = [],
	introduction = [],
	image,
	thumbnail,
	text = [],
	openInNewWindow,
	buttonLabel,
	url,
}) => {
	const { track } = useAnalytics();

	const target = openInNewWindow ? "_blank" : "_self";

	const handleCTAClick = useCallback(() => {
		track("reinsurance_block_cta_click", { ctaLabel: buttonLabel, title: title.join(",") });
	}, [buttonLabel, title]);

	return (
		<div className="sdp-reinsurance">
			<div className="sdp-reinsurance__container">
				{title && (
					<>
						<h2 className="sdp-reinsurance__title">
							<Paragraphs paragraphs={title} enableHTMLFormatting />
						</h2>
						{introduction && (
							<div className="sdp-reinsurance__introduction">{introduction}</div>
						)}
						<Separator />
					</>
				)}
				<div className="sdp-reinsurance__texte">
					<Paragraphs paragraphs={text} enableHTMLFormatting />
				</div>
				{url && buttonLabel && (
					<RelativeLink
						to={{ pathname: url }}
						onClick={handleCTAClick}
						className="sdp-reinsurance__cta"
						variant="secondary"
						target={target}
						data-testid="reinsurance-cta"
					>
						{buttonLabel}
					</RelativeLink>
				)}
			</div>
			{image && (
				<div className="sdp-reinsurance__image">
					<ProgressiveImg
						sizes={IMG_SIZES}
						resolution={resolution}
						src={image}
						thumbnail={thumbnail}
					/>
				</div>
			)}
		</div>
	);
};

SmartDPReInsurance.propTypes = {
	resolution: PropTypes.string,
	title: PropTypes.array,
	introduction: PropTypes.array,
	image: PropTypes.string,
	thumbnail: PropTypes.string,
	text: PropTypes.array,
	openInNewWindow: PropTypes.bool,
	buttonLabel: PropTypes.string,
	url: PropTypes.string,
};

export default memo(SmartDPReInsurance);
