import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchMerchandisings } from "app/pages/Merchandising/merchandisingActionCreators";
import {
	getCategorizedVisibleMerchandisings,
	getOneMerchBannerMerchandising,
} from "app/pages/Merchandising/merchandisingSelector";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import DynamicBlocksRenderer from "./DynamicBlocksRenderer";

const DynamicBlocksRendererContainer = props => {
	return <DynamicBlocksRenderer {...props} />;
};

DynamicBlocksRendererContainer.propTypes = {
	blocks: PropTypes.array,
	DividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	merchandisings: PropTypes.array,
	alternateColors: PropTypes.bool,
	oneMerchBannerMerchandising: PropTypes.object,
	userIsConnected: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		userIsConnected: isAuthenticated(state),
		merchandisings: getCategorizedVisibleMerchandisings(state),
		oneMerchBannerMerchandising: getOneMerchBannerMerchandising(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchMerchandisings }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DynamicBlocksRendererContainer);
