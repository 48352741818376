import { memo } from "react";
import PropTypes from "prop-types";
import { DynamicBlocksRendererContainer } from "../../";
import "./MultiBlocks.scss";

const MultiBlocks = ({
	blocks = [],
	DividerComponent,
	fetchMerchandisings,
	merchandisings = [],
}) => {
	return (
		<div className="multi-blocks">
			<DynamicBlocksRendererContainer
				blocks={blocks}
				DividerComponent={DividerComponent}
				fetchMerchandisings={fetchMerchandisings}
				merchandisings={merchandisings}
				alternateColors={false}
			/>
		</div>
	);
};

MultiBlocks.propTypes = {
	blocks: PropTypes.array,
	DividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	merchandisings: PropTypes.array,
};

export default memo(MultiBlocks);
