import { memo } from "react";
import "./SmartDPReasons.scss";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";

const SmartDPReasons = ({ reasonsToBook }) => {
	return (
		<div className="sdp-reasons">
			{reasonsToBook &&
				reasonsToBook.map((reason = {}, index) => (
					<div className="sdp-reasons__item" key={index}>
						<div className="sdp-reasons__icon-container">
							<img src={reason.image} />
						</div>
						<h4 className="sdp-reasons__title">{reason.title}</h4>
						<div className="sdp-reasons__texte">
							<Paragraphs paragraphs={reason.description} enableHTMLFormatting />
						</div>
					</div>
				))}
		</div>
	);
};

SmartDPReasons.propTypes = {
	reasonsToBook: PropTypes.array,
};

SmartDPReasons.defaultProps = {
	reasonsToBook: [],
};

export default memo(SmartDPReasons);
