import { memo } from "react";
import PropTypes from "prop-types";
import PhotoArticle from "./PhotoArticle";
import { htmlSanitize } from "app/utils/sanitize";
import "./PhotoArticles.scss";

const PhotoArticles = ({ title, articles = [], isMobile }) => {
	return (
		<div className="photo-articles">
			<div
				className="photo-articles__title"
				dangerouslySetInnerHTML={{ __html: htmlSanitize(title) }}
			/>
			<div className="photo-articles__list">
				{articles.map((article, index) => {
					return (
						<div key={index} className="photo-articles__item">
							<PhotoArticle {...article} isMobile={isMobile} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

PhotoArticles.propTypes = {
	title: PropTypes.string,
	articles: PropTypes.array,
	isMobile: PropTypes.bool,
};

export default memo(PhotoArticles);
