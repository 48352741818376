import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import { resolutionPropType } from "app/utils/propTypes";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";
import "./CurrentMerchCollectionItem.scss";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import { htmlSanitize } from "app/utils/sanitize";

const IMG_SIZES_MOBILE = {
	small: { width: 265, height: 250 },
	medium: { width: 265, height: 250 },
};

const IMG_SIZES_DESKTOP = {
	large: { width: 420, height: 400 },
	xlarge: { width: 420, height: 400 },
};

const CLOUDINARY_OPTIONS = ["c_fill"];

const CurrentMerchCollectionItem = ({
	resolution,
	picture = {},
	label,
	merchandisingTitle,
	merchandisingSubtitle,
	introduction,
	merchandisingCode,
	position,
	indexCarousel,
	onClick = () => {},
}) => {
	const history = useHistory();

	const handleMerchClick = useCallback(() => {
		history.push({
			pathname: "/merch",
			search: `?${stringify({ code: merchandisingCode })}`,
		});
		onClick({ merchandisingCode, position, indexCarousel });
	}, [merchandisingCode, position, indexCarousel]);

	return (
		<div
			className="current-merch-collection-item"
			data-testid={`current-merch-item-${merchandisingCode}`}
		>
			<div onClick={handleMerchClick}>
				<div className="current-merch-collection-item__picture">
					{resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL ? (
						<BackgroundProgressiveImg
							sizes={IMG_SIZES_MOBILE}
							cloudinaryOptions={CLOUDINARY_OPTIONS}
							src={picture.url}
							thumbnail={picture.thumbnail}
						/>
					) : (
						<ProgressiveImg
							className="progressive-img"
							sizes={IMG_SIZES_DESKTOP}
							src={picture.url}
							thumbnail={picture.thumbnail}
						/>
					)}
				</div>
				<div className="current-merch-collection-item__footer">
					<div className="current-merch-collection-item__footer-left">
						<div
							className="current-merch-collection-item__label"
							dangerouslySetInnerHTML={{
								__html: htmlSanitize(merchandisingTitle || label),
							}}
						/>
						<div
							className="current-merch-collection-item__intro"
							dangerouslySetInnerHTML={{
								__html: htmlSanitize(merchandisingSubtitle || introduction),
							}}
						/>
					</div>
					<div className="current-merch-collection-item__footer-right">
						<ProductLinkContainer handleProductClick={handleMerchClick}>
							<IconRight width={20} height={20} />
						</ProductLinkContainer>
					</div>
				</div>
			</div>
		</div>
	);
};

CurrentMerchCollectionItem.propTypes = {
	label: PropTypes.string,
	picture: PropTypes.object,
	introduction: PropTypes.string,
	resolution: resolutionPropType,
	position: PropTypes.number,
	indexCarousel: PropTypes.number,
	merchandisingCode: PropTypes.string,
	merchandisingTitle: PropTypes.string,
	merchandisingSubtitle: PropTypes.string,
	onClick: PropTypes.func,
};

export default memo(CurrentMerchCollectionItem);
