import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./ProductsCarouselBlock.scss";
import { messagePropType } from "app/utils/propTypes";
import { PPProductsCarouselContainer } from "app/pages/.shared/ProductsCarousel";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const ProductsCarouselBlock = ({ title, cta = {}, onItemClick = () => {} }) => {
	const { track } = useAnalytics();

	const handleCTAClick = useCallback(() => {
		track("products_carousel_block_cta_click", { ctaLabel: cta.label });
	}, [cta.label]);

	return (
		<div className="pp-carousel-block">
			{title && (
				<h3 className="pp-carousel-block__title">
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</h3>
			)}
			<div className="pp-carousel-block__products">
				<PPProductsCarouselContainer onItemClick={onItemClick} />
			</div>
			<RelativeLink
				to={{ pathname: "/listing" }}
				onClick={handleCTAClick}
				className="pp-carousel-block__cta"
				variant="secondary"
				data-testid="pp-carousel-block-cta"
			>
				{cta.label}
			</RelativeLink>
		</div>
	);
};

ProductsCarouselBlock.propTypes = {
	title: messagePropType,
	cta: PropTypes.object,
	onItemClick: PropTypes.func,
};

export default memo(ProductsCarouselBlock);
