import { memo } from "react";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Separator from "app/pages/.shared/Separator";
import CurrentMerchCollection from "app/pages/.shared/CurrentMerchCollection/CurrentMerchCollection";
import "./MerchCarouselBlock.scss";
import { messagePropType } from "app/utils/propTypes";

const MerchCarouselBlock = ({ title, introduction, merchandisingTitle, merchandisings = [] }) => {
	return (
		<div className="merch-carousel-block">
			{title && (
				<>
					<h2 className="merch-carousel-block__title">
						<Paragraphs paragraphs={title} enableHTMLFormatting />
					</h2>
					{introduction && (
						<div className="merch-carousel-block__introduction">
							<Paragraphs paragraphs={introduction} enableHTMLFormatting />
						</div>
					)}
					<Separator />
				</>
			)}
			<h3 className="merch-carousel-block__merch-title">
				<Paragraphs paragraphs={merchandisingTitle} enableHTMLFormatting />
			</h3>
			<div className="merch-carousel-block__merchandisings">
				<CurrentMerchCollection merchandisingList={merchandisings} />
			</div>
		</div>
	);
};

MerchCarouselBlock.propTypes = {
	title: messagePropType,
	introduction: messagePropType,
	merchandisings: PropTypes.array,
	merchandisingTitle: messagePropType,
};

export default memo(MerchCarouselBlock);
