import { memo } from "react";
import PropTypes from "prop-types";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import classNames from "classnames";
import "./PhotoArticle.scss";
import Paragraphs from "app/pages/.shared/Paragraphs";

const IMG_SIZES_DESKTOP = {
	large: { width: 270 },
	xlarge: { width: 270 },
	medium: { width: 710, height: 354 },
	small: { width: 500, height: 249 },
};

const PhotoArticle = ({
	text,
	title,
	photoPosition,
	desktopPhoto,
	desktopPhotoThumbnail,
	mobilePhoto,
	mobilePhotoThumbnail,
	isMobile,
	sticker,
}) => {
	const blockClassName = classNames("photo-article", {
		"photo-article--reverse": photoPosition === "right",
	});

	return (
		<div className={blockClassName}>
			<div className="photo-article__photo">
				{isMobile ? (
					<ProgressiveImg
						sizes={IMG_SIZES_DESKTOP}
						thumbnail={mobilePhotoThumbnail}
						src={mobilePhoto}
					/>
				) : (
					<BackgroundProgressiveImg
						sizes={IMG_SIZES_DESKTOP}
						cloudinaryOptions={["c_fill", "g_auto"]}
						thumbnail={desktopPhotoThumbnail}
						backgroundPosition="center"
						src={desktopPhoto}
					/>
				)}
			</div>
			<div className="photo-article__content">
				{sticker && <img className="photo-article__sticker" src={sticker} />}
				<div className="photo-article__title">
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</div>
				<div className="photo-article__text">
					<Paragraphs paragraphs={text} enableHTMLFormatting />
				</div>
			</div>
		</div>
	);
};

PhotoArticle.propTypes = {
	sticker: PropTypes.string,
	text: PropTypes.string,
	title: PropTypes.string,
	photoPosition: PropTypes.string,
	desktopPhoto: PropTypes.string,
	mobilePhoto: PropTypes.string,
	desktopPhotoThumbnail: PropTypes.string,
	mobilePhotoThumbnail: PropTypes.string,
	isMobile: PropTypes.bool,
};

export default memo(PhotoArticle);
